
.performers-page {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .performers-list-container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    background: #fff;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 
        0px 221px 62px 0px rgba(221, 221, 221, 0.01), 
        0px 142px 57px 0px rgba(246, 247, 250, 0.05), 
        0px 80px 48px 0px rgba(173, 173, 173, 0.16), 
        0px 35px 35px 0px rgba(229, 230, 231, 0.27), 
        0px 9px 19px 0px rgba(210, 210, 211, 0.31);
    border-radius: 8px;
    padding: 40px 40px 25px 40px;
  }
  
  .assets-table-body {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .table-data {
    flex: 1;
    text-align: center;
  }
  
  .table-data b {
    font-weight: bold;
  }
  
  .performer-data-form {
    display: flex;
    align-items: center;
  }
  
  .update-performer-name-input {
    flex: 1;
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .performer-update-button {
    padding: 5px 10px;
    background-color: #343a40;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .performer-update-button:hover {
    background-color: #495057;
  }
  
  .create-performer {
    display: flex;
    margin-top: 20px;
  }
  
  .create-performer input {
    flex: 1;
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .create-performer button {
    padding: 5px 10px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .create-performer button:hover {
    background-color: #5a6268;
  }
  