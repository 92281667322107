/* UploadLogs.css */
.upload-logs-container {
    padding: 20px;
}

.upload-logs-table {
    width: 100%;
    border-collapse: collapse;
}

.upload-logs-table th, .upload-logs-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.upload-logs-table th {
    background-color: #f2f2f2;
    text-align: left;
}